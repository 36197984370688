.workflow-detail {
  .workflow-detail-image-list {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .workflow-detail-item {
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.847058823529412);
    line-height: 22px;
  }
}
