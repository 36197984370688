.box1 {
  height: 180px;
  border-radius: 8px 8px 8px 8px;
  box-shadow: 0px 0px 20px 0px rgba(57, 113, 228, 0.2);
  box-sizing: border-box;
  padding: 20px 30px;
  background-color: #fff;
}

.tag1 {
  font-size: 16px;
  padding: 10px 20px;
  background: linear-gradient(90deg, #c9ebe9 0%, rgba(255, 255, 255, 0) 100%);
  display: inline-block;
}

.common-button {
  margin: 0 20px 10px;
  cursor: pointer;
}

.waterfall-container {
  column-count: 4;
  column-gap: 10px;

  counter-reset: count;
  .waterfall-container-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
  }
}
