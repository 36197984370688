@import 'normalize.css';
@import 'antd/dist/antd.less';

html {
  body {
    // font-family: monospace, 'PingFang SC', sans-serif;
    // user-select: none;
    @media (max-width: 1024px) {
      min-width: 1024px;
    }
  }
}

.ant-tabs.atom-tabs {
  .ant-tabs-nav {
    margin-bottom: 5px;
    &::before {
      display: none;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 5px;
    }
  }

  .ant-tabs-tab {
    border: 0;
    background-color: #fff;

    &.ant-tabs-tab-active {
      background-color: #1890ff;
      * {
        color: #fff !important;
      }
      .ant-tabs-tab-btn {
        color: #fff !important;
      }
    }
  }

  .ant-tabs-content {
    background-color: #fff;
    padding: 20px;
    // box-shadow: 0px 3px 10px 0px rgba(57, 113, 228, 0.1);
  }
}

.atom-table {
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    display: none;
  }
  .ant-table-thead > tr > th {
    // border-bottom: 0;
    border-top: 1px solid #f0f0f0;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    font-weight: normal;
    background-color: #f7f8fc;
    color: #586078;

    &:first-child {
      border-left: 1px solid #f0f0f0;
    }
    &:last-child {
      border-right: 1px solid #f0f0f0;
    }
  }
}

.atom-shadow {
  box-shadow: 0px 3px 10px 0px rgba(57, 113, 228, 0.1);
}

.atom-btn {
  border-radius: 20px;
}

.atom-input-search {
  .ant-btn {
    border-left: 0;
  }
}

.atom-menu {
  .ant-dropdown-menu-item {
    &:hover {
      background-color: #e6f7ff;
      color: #1890ff;
      a {
        color: #1890ff;
      }
    }
  }
}

.atom-breadcrumb {
  &.multi {
    li {
      &:first-child {
        &:before {
          background: #9da4b4;
        }
      }
    }
  }
  li {
    position: relative;
    line-height: 1.4;

    &:first-child {
      padding-left: 10px;
      &:before {
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        display: block;
        background: #1890ff;
        border-radius: 2px 2px 2px 2px;
        width: 4px;
      }
    }
  }
}

.table-bordered {
  td {
    border: 1px solid #e5e9f1;
  }
}
