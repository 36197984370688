.user-portal {
  height: 100vh;
  background-color: #f5f7fb;

  > .header {
    height: 55px;
    line-height: 55px;
    background: #ffffff;
    padding: 0 24px;
    box-shadow: 0px 2px 10px rgba(57, 113, 228, 0.1);
    z-index: 10;
    .logo {
      float: left;
      font-size: 18px;
      font-weight: 600;
      color: #1e2f50;
    }
  }

  .site-layout-side {
    background-color: #fff;
    box-shadow: 2px 0px 10px rgba(57, 113, 228, 0.1);
  }
  .site-layout-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }
  .ant-menu.ant-menu-root {
    > .ant-menu-item {
      padding-left: 14px !important;
    }
  }
  .nav.ant-menu {
    // padding: 0 10px;
    .ant-menu-item {
      border-radius: 6px;
      margin: 0 10px 8px;
      width: calc(100% + 1px - 20px);
    }
    .ant-menu-item-selected {
      color: #fff;
      background: linear-gradient(to right, #1e66eb, #20a3ea);
      a {
        color: #fff;
      }
      &::after {
        display: none;
      }
    }
    .margin-left-10 {
      margin-left: 10px;
    }
    .ant-menu-submenu-selected {
      position: relative;

      > .ant-menu-submenu-title {
        background-color: #e6f7ff;
        &::after {
          content: '';
          border-right: 3px solid #1890ff;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
        }
      }
    }

    .ant-menu-sub.ant-menu-inline {
      background-color: #fff;
    }
  }
}
